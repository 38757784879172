import React from "react";
import "./Benefits.css";
const BenefitsHead = () => {
  return (
    <div className="benefitHead">
      <h3>
         Benefits for <span>Prizma Academy</span> students:{" "}
      </h3>
    </div>
  );
};

export default BenefitsHead;
