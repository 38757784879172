import React from 'react'
import "./A2Page.css"
import A2CourseDetails from './A2CourseDetails'
const A2Main = () => {
  return (
    <div className='a2PageMainContainer'>
      <A2CourseDetails/>
    </div>
  )
}

export default A2Main
