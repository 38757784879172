import React from "react";

const AboutUsHead = () => {
  return (
    <div className="aboutUsHeadContainer">
      <h2>
        About Us -<span> Prizma Academy</span>
      </h2>
    </div>
  );
};

export default AboutUsHead;
