import React from 'react'
import C1CourseDetails from './C1CourseDetails'
import "./C1Page.css"
const C1Main = () => {
  return (
    <div className='c1PageMainContainer'>
      <C1CourseDetails/>
    </div>
  )
}

export default C1Main
