import React from "react";
import "./ExamPrep.css";

const ExamPrepHead = () => {
  return (
    <div className="examPrepHeadContainer">
      <h1>
        Exam <span>Prepration</span>
      </h1>
    </div>
  );
};

export default ExamPrepHead;
