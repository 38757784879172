import React from "react";
import "./A1Page.css";
import A1CourseDetails from "./A1CourseDetails";

const A1PageMain = () => {
  return <div className="a1PageMainContainer">
    <A1CourseDetails/>
  </div>;
};

export default A1PageMain;
