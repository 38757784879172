import React from 'react'
import B1CourseDetails from './B1CourseDetails'
import "./B1Page.css"

const B1Main = () => {
  return (
    <div className='b1PageMainContainer'>
      <B1CourseDetails/>
    </div>
  )
}

export default B1Main
