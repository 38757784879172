import React from "react";
import "./Policy.css";
const Terms = () => {
  return (
    <div className="policyContainer">
      <div className="policyHead">
        <h2>
          <span>Terms</span> and <span>Conditions</span>
        </h2>
      </div>
      <div className="policyContent">
        <ol>
          <li>
            <h3>
              1.<span>Terms</span>
            </h3>
            <p>
              By accessing the website at
              <span> https://www.prizmaacademy.com/</span>, you are agreeing to
              be bound by these terms of service, all applicable laws and
              regulations, and agree that you are responsible for compliance
              with any applicable local laws. If you do not agree with any of
              these terms, you are prohibited from using or accessing this site.
              The materials contained in this website are protected by
              applicable copyright and trademark law.
            </p>
          </li>
          <li>
            <h3>
              2.<span>Use License</span>
            </h3>
            <p>
              Permission is granted to temporarily download one copy of the
              materials (information or software) on Prizma Academy’s website
              for personal, non-commercial transitory viewing only. This is the
              grant of a license, not a transfer of title, and under this
              license you may not:
            </p>
            <ul>
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose, or for any public
                display (commercial or non-commercial);
              </li>
              <li>
                attempt to decompile or reverse engineer any software contained
                on Prizma Academy’s website;
              </li>
              <li>
                remove any copyright or other proprietary notations from the
                materials; or
              </li>
              <li>
                transfer the materials to another person or “mirror” the
                materials on any other server.
              </li>
            </ul>
            <p>
              This license shall automatically terminate if you violate any of
              these restrictions and may be terminated by Prizma Academy at any
              time. Upon terminating your viewing of these materials or upon the
              termination of this license, you must destroy any downloaded
              materials in your possession whether in electronic or printed
              format.
            </p>
          </li>
          <li>
            <h3>
              3.<span>Disclaimer</span>
            </h3>
            <p>
              The materials on Prizma Academy’s website are provided on an ‘as
              is’ basis. Prizma Academy makes no warranties, expressed or
              implied, and hereby disclaims and negates all other warranties
              including, without limitation, implied warranties or conditions of
              merchantability, fitness for a particular purpose, or
              non-infringement of intellectual property or other violation of
              rights.
            </p>
            <p>
              Further, Prizma Academy does not warrant or make any
              representations concerning the accuracy, likely results, or
              reliability of the use of the materials on its website or
              otherwise relating to such materials or on any sites linked to
              this site.
            </p>
          </li>
          <li>
            <h3>
              4.<span>Limitations</span>
            </h3>
            <p>
              In no event shall Prizma Academy or its suppliers be liable for
              any damages (including, without limitation, damages for loss of
              data or profit, or due to business interruption) arising out of
              the use or inability to use the materials on Prizma Academy’s
              website, even if Prizma Academy or a Prizma Academy authorized
              representative has been notified orally or in writing of the
              possibility of such damage. Because some jurisdictions do not
              allow limitations on implied warranties, or limitations of
              liability for consequential or incidental damages, these
              limitations may not apply to you.
            </p>
          </li>
          <li>
            <h3>
              5.<span>Accuracy of materials</span>
            </h3>
            <p>
              The materials appearing on Prizma Academy’s website could include
              technical, typographical, or photographic errors. Prizma Academy
              does not warrant that any of the materials on its website are
              accurate, complete or current. Prizma Academy may make changes to
              the materials contained on its website at any time without notice.
              However, Prizma Academy does not make any commitment to update the
              materials.
            </p>
          </li>
          <li>
            <h3>
              {" "}
              6.<span>Links</span>
            </h3>
            <p>
              Prizma Academy has not reviewed all of the sites linked to its
              website and is not responsible for the contents of any such linked
              site. The inclusion of any link does not imply endorsement by
              Prizma Academy of the site. Use of any such linked website is at
              the user’s own risk.
            </p>
          </li>
          <li>
            <h3>
              7.<span>Modifications</span>
            </h3>
            <p>
              Prizma Academy may revise these terms of service for its website
              at any time without notice. By using this website, you are
              agreeing to be bound by the then current version of these terms of
              service.
            </p>
          </li>
          <li>
            <h3>
              8.<span>Governing Law</span>
            </h3>
            <p>
              Any disputes arising are subject to Bangalore jurisdiction only.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Terms;
