import React from 'react'
import "./../../A2Page/A2Page.css";

const C2WhyHead = () => {
  return (
    <div className="a2WhyHeadContainer">
    <h2>
      C2 <span>German Classes</span> in Bangalore at Prizma Academy
    </h2>
    <p>
      Prizma Academy stands out as a premier institution for language
      learning, offering comprehensive and effective C1 German Classes in
      Bangalore. Here’s why our C1 German Classes in Bangalore are unmatched
      in quality and effectiveness:
    </p>
  </div>
  )
}

export default C2WhyHead
