import React from "react";
import "./WhyUs.css";
const WhyUsHead = () => {
  return (
    <div className="whyUsHeadContainer">
      <h2>
        Why <span> Prizma Academy </span>?
      </h2>
    </div>
  );
};

export default WhyUsHead;
